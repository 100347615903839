<template>
  <div>
    <img src="@galileo/assets/images/bank/OpenBanking.svg" alt="Volt logo" class="ob-logo" />

    <p class="card-title-mobile">{{ $t('SendMoneyVoltInfo.Title').value }}</p>

    <p class="content-center text-center">
      {{ $t('VoltInfoContent.Description').value }}
    </p>

    <hr class="h-px my-8 bg-gray-lightest border-0" />

    <div class="text-left mt-6">
      <h3 class="font-semibold mb-4">{{ $t('VoltInfoContent.HowItWorksTitle').value }}</h3>
      <div class="list-item">
        <div class="mr-4">
          <div class="bullet-point">1</div>
        </div>

        <div>
          <span class="list-title">
            {{ $t('VoltInfoContent.HowItWorksHeading1').value }}
          </span>
          <p class="list-description mb-2">
            {{ $t('VoltInfoContent.HowItWorksDescription1').value }}
          </p>
        </div>
      </div>
      <div class="list-item">
        <div class="mr-4">
          <div class="bullet-point">2</div>
        </div>
        <div>
          <span class="list-title">
            {{ $t('VoltInfoContent.HowItWorksHeading2').value }}
          </span>
          <p class="list-description">
            {{ $t('VoltInfoContent.HowItWorksDescription2').value }}
          </p>
        </div>
      </div>
      <div class="list-item">
        <div class="mr-4">
          <div class="bullet-point">3</div>
        </div>
        <div>
          <span class="list-title">
            {{ $t('VoltInfoContent.HowItWorksHeading3').value }}
          </span>
          <p class="list-description">
            {{ $t('VoltInfoContent.HowItWorksDescription3').value }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'

export default defineComponent({
  setup(props, { emit }) {
    const i18nStore = useI18nStore()
    const { $t } = i18nStore

    return {
      $t,
    }
  },
})
</script>

<style scoped>
.list-item {
  @apply flex flex-row mb-4 text-sm;
}

.list-title {
  @apply font-medium text-primary-text leading-6 inline-block pb-2;
}

.list-description {
  @apply font-normal text-secondary-text leading-4;
}

.bullet-point {
  @apply pt-1;
  @apply w-8 h-8 block bg-orange-light rounded-full text-center text-blue;
}

.card-title-mobile {
  @apply text-3xl font-semibold mb-4 content-center text-center;
  @screen sm {
    @apply hidden;
  }
}

.ob-logo {
  @apply mx-auto mb-8 h-20 w-20;
}
</style>
