<template>
  <div>
    <div class="info-section">
      <div>
        <img
          src="@galileo/assets/images/branding/logo_payto.svg"
          alt="Volt logo"
          class="payto-logo"
        />
      </div>
      <div class="mobile-title">
        <h2>{{ $t('SendMoneyVoltPayToStartPage.MobileTitle').value }}</h2>
      </div>
      <div>
        <p class="page-description">
          {{ $t('SendMoneyVoltPayToStartPage.PageDescription').value }}
        </p>
      </div>
    </div>

    <hr class="h-px my-8 bg-gray-lightest border-0" />

    <div class="how-to-details">
      <h2 class="how-to">
        {{ $t('SendMoneyVoltPayToStartPage.HowItWorks').value }}
      </h2>
      <div class="how-to-items">
        <div class="list-item">
          <div class="mr-4">
            <div class="bullet-point">1</div>
          </div>

          <div>
            <span class="list-title">
              {{ $t('SendMoneyVoltPayToStartPage.PayID').value }}
            </span>
            <p class="list-description mb-2">
              {{ $t('SendMoneyVoltPayToStartPage.PayIDDetails').value }}
            </p>
          </div>
        </div>
        <div class="list-item">
          <div class="mr-4">
            <div class="bullet-point">2</div>
          </div>

          <div>
            <span class="list-title">
              {{ $t('SendMoneyVoltPayToStartPage.LogIn').value }}
            </span>
            <p class="list-description mb-2">
              {{ $t('SendMoneyVoltPayToStartPage.LogInDetails').value }}
            </p>
          </div>
        </div>
        <div class="list-item">
          <div class="mr-4">
            <div class="bullet-point">3</div>
          </div>

          <div>
            <span class="list-title">
              {{ $t('SendMoneyVoltPayToStartPage.PaymentSent').value }}
            </span>
            <p class="list-description mb-2">
              {{ $t('SendMoneyVoltPayToStartPage.PaymentSentDetails').value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

export default {
  name: 'SendMoneyVoltPayToStartPage',
  components: {},

  setup() {
    const i18nStore = useI18nStore()
    const { $t } = i18nStore

    return {
      $t,
    }
  },
}
</script>

<style scoped>
.list-item {
  @apply flex flex-row mb-4 text-sm;
}

.list-title {
  @apply font-medium text-primary-text leading-6 inline-block pb-2;
}

.list-description {
  @apply font-normal text-secondary-text leading-4;
}

.bullet-point {
  @apply pt-1;
  @apply w-8 h-8 block bg-orange-light rounded-full text-center text-blue;
}
.how-to {
  @apply text-lg mb-4 mt-4 font-semibold text-left leading-8;
}
.page-description {
  @apply text-base font-normal leading-6 content-center text-center mt-4;
  color: #636e82;
}
.info-section {
  @apply mt-2.5;
}

.how-to-details {
  @apply w-80;

  /* @media (max-width: 600px) {
    @apply w-72 ml-4;
  } */
}
.mobile-title {
  @apply hidden;
}

@media (max-width: 600px) {
  .mobile-title {
    @apply block mt-5 content-center text-center text-3xl font-semibold leading-10;
    width: 360px;
  }

  .payto-logo {
    @apply w-20 h-20;
  }

  .how-to-details {
    @apply w-72 ml-4;
  }
}

.payto-logo {
  @apply mx-auto mb-8;

  /* @media (max-width: 600px) {
      @apply w-20 h-20;
    } */
}
</style>
