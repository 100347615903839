<template>
  <AppCard class="volt-card" centered>
    <template #header>
      <AppCardHeader class="volt-card-header">
        <template #left>
          <XeBackButton
            icon="<"
            :name="$t('RecipientDetailsModal.CloseTooltipText').value"
            analytics-name="volt-info-back-button"
            @click="goBack"
          />
        </template>

        <h2 class="volt-card-title">{{ $t('SendMoneyVoltInfo.Title').value }}</h2>
      </AppCardHeader>
    </template>

    <SendMoneyVoltPayToStartPage v-if="isPayTo" />
    <VoltInfoContent v-else />

    <template #footer>
      <AppCardFooter>
        <AppButton
          theme="primary"
          analytics-name="volt-info-continue-button"
          class="continue-button"
          @click="onContinue"
        >
          {{ $t('SendMoneyVoltInfo.ButtonContinue').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import { useI18nStore, useSendMoneyStore, useAnalyticsStore, useAuthStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { AppButton, AppCard, AppCardHeader, AppCardFooter } from '@oen.web.vue2/ui'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton.vue'

import VoltInfoContent from '@galileo/components/Views/SendMoney/VoltInfo/VoltInfoContent.vue'
import SendMoneyVoltPayToStartPage from '@galileo/components/Views/SendMoney/VoltInfo/SendMoneyVoltPayToStartPage.vue'
import useOpenBanking from '@galileo/composables/useOpenBanking'
import { TRANSFER_TYPE } from '@galileo/constants/sendMoneyFlow.const'

export default defineComponent({
  components: {
    AppButton,
    AppCard,
    AppCardHeader,
    AppCardFooter,
    XeBackButton,
    VoltInfoContent,
    SendMoneyVoltPayToStartPage,
  },
  props: {},
  events: [],
  setup(props, { emit }) {
    const i18nStore = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const { $t } = i18nStore
    const router = useRouter()
    const senderCountry = authStore.userProfile?.country
    const { isPayTo } = useOpenBanking()

    onMounted(() => {
      if(sendMoneyStore.form.transferType !== TRANSFER_TYPE.SEND_MONEY_FLOW) {
        sendMoneyStore.activeStepIdx = null
      }
      analyticsStore.track({
        event: SEGMENT_EVENTS.OPEN_BANKING_INFO_PAGE_DISPLAYED,
        traits: {
          accountType: authStore.user.customer.accountType,
        },
      })
    })

    const onContinue = () => {
      sendMoneyStore.hasVoltInfoBeenShown = true
      router.replace('/creating')
    }

    const goBack = async () => {
      if (sendMoneyStore.form.transferType === TRANSFER_TYPE.QUICK_TRANSFER) {
        await router.replace('/quick-transfer')
      } else if (sendMoneyStore.form.transferType === TRANSFER_TYPE.FUND_BALANCE) {
        await router.replace('/fund-balance')
      } else {
        await router.replace('/send-money/summary')
      }
    }

    return {
      $t,
      onContinue,
      goBack,
      senderCountry,
      isPayTo,
    }
  },
})
</script>

<style scoped>
.card-footer {
  .continue-button {
    @apply w-full;
  }
}

.volt-card-title {
  @apply hidden;
  @screen sm {
    @apply block;
  }
}

@media (max-width: 600px) {
  .volt-card-header {
    @apply hidden;
  }
}
</style>
